import { gql } from '@apollo/client'
import {
  CORE_SINGLE_PRODUCT_FIELDS,
  CORE_SINGLE_MINER_FIELDS,
  CORE_BEST_MINER_FIELDS,
  CORE_BEST_PRODUCT_FIELDS,
  COMMON_PRODUCT_OPTIONAL_FIELDS,
} from './minerListingFragments'

const DEFAULT_LIMIT = 1000

const HARDWARE_LIST_COMMON_FILTERS = `
  productListingPrice: {
    greaterThanOrEqualTo: $minerPriceMin
    lessThanOrEqualTo: $minerPriceMax
  }
  productPricePerHashrate: {
    greaterThanOrEqualTo: $minerPerHashratePriceMin
    lessThanOrEqualTo: $minerPerHashratePriceMax
  }
  minerModelAlgorithm: { in: $minerAlgorithmsFilters }
  manufacturerName: { in: $manufacturerNameFilters }
  minerModelName: { in: $minerModelNameFilters }
  minerModelPowerConsumptionWatts: {
    greaterThanOrEqualTo: $minerPowerMin
    lessThanOrEqualTo: $minerPowerMax
  }
`

export const getFeaturedMiners = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  query FeaturedMiners {
    allAvailableProducts(
      orderBy: MINER_MODEL_NAME_ASC
      condition: { productDisplayCategory: FEATURED }
      filter: { minerModelAlgorithm: { distinctFrom: "SCRYPT" } }
    ) {
      edges {
        node {
          ...CoreSingleMinerFields
          ...CoreSingleProductFields
          countryName
          facilityDisplayName
          isProductFeatured
          hostedTargetOnlineDate
        }
      }
    }
  }
`

/**
 * A query with combined all 3 types of hardware list sections:
 * 1. Featured section - allAvailableProducts query with productDisplayCategory = FEATURED
 * 2. Hosted by Compass miners section - allAvailableProducts query with productDisplayCategory = HOSTED
 * 3. At-home-mining section - allAvailableProducts with productDisplayCategory = 'NON_HOSTED'
 *
 * 4. Marketplace section - allBestMinerProducts query (productDisplayCategory = 'MARKET_PLACE') to display a product with the highest compass score
 *
 */
export const getAllProductsLists = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  ${CORE_BEST_MINER_FIELDS}
  ${CORE_BEST_PRODUCT_FIELDS}
  query AllProductsLists(
    # variables to define what sections we need to fetch
    $includeFeaturedProducts: Boolean!
    $includeHostedProducts: Boolean!
    $includeNonHostedProducts: Boolean!
    $includeBestProducts: Boolean!

    # filters
    $orderByValue: [AvailableProductsOrderBy!]
    $bestProductsSortByValue: BestMpFieldOrderNullsLast!
    $bestProductsOrderByValue: BestMpAscDescOrderNullsLast!
    $minerPriceMin: Float
    $minerPriceMax: Float
    $minerPerHashratePriceMin: Float
    $minerPerHashratePriceMax: Float
    $minerAlgorithmsFilters: [String!]
    $manufacturerNameFilters: [String!]
    $minerModelNameFilters: [String!]
    $minerPowerMin: Int
    $minerPowerMax: Int
    $countryNameFilters: [String!]
  ) {
     # Section 1: Featured products (productDisplayCategory === FEATURED)
    featuredProducts: allAvailableProducts(
      first: ${DEFAULT_LIMIT}
      orderBy: $orderByValue
      condition: {
        productDisplayCategory: FEATURED
      }
      filter: {
        countryName: { in: $countryNameFilters }
        ${HARDWARE_LIST_COMMON_FILTERS}
      }
    ) @include(if: $includeFeaturedProducts) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        countryName
        facilityDisplayName
        isProductFeatured
        hostedTargetOnlineDate
        productDateListed
      }
    }

    # Section 2: Hosted by Compass products (productDisplayCategory === HOSTED)
    hostedProducts: allAvailableProducts(
      first: ${DEFAULT_LIMIT}
      orderBy: $orderByValue
      condition: {
        productDisplayCategory: HOSTED
      },
      filter: {
        countryName: { in: $countryNameFilters }
        ${HARDWARE_LIST_COMMON_FILTERS}
      }
    ) @include(if: $includeHostedProducts) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        countryName
        facilityDisplayName
        isProductFeatured
        hostedTargetOnlineDate
        productDateListed
      }
    }

    # Section 3: AHM products (productDisplayCategory === NON_HOSTED)
    nonHostedProducts: allAvailableProducts(
      first: ${DEFAULT_LIMIT}
      orderBy: $orderByValue
      condition: { productDisplayCategory: NON_HOSTED }
      filter: {
        ${HARDWARE_LIST_COMMON_FILTERS}
      }
    ) @include(if: $includeNonHostedProducts) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        nonhostedEarliestShippingDate
        nonhostedShippingTimeDescription
        productDateListed
      }
    }
    # Section 4: Marketplace miners (productDisplayCategory === MARKET_PLACE)
    marketplaceBestProducts: allBestMinerProducts(
      first: ${DEFAULT_LIMIT}
      sortAscDescNullsLast: $bestProductsOrderByValue
      sortByFieldNullsLast: $bestProductsSortByValue
      filter: {
        productDisplayCategory: { equalTo: MARKETPLACE }
        countryName: { in: $countryNameFilters }
        ${HARDWARE_LIST_COMMON_FILTERS}
      }
    ) @include(if: $includeBestProducts) {
      nodes {
        ...CoreBestMinerFields
        ...CoreBestProductFields
        countryName
        facilityDisplayName
        hostedTargetOnlineDate
        productDateListed
      }
    }
  }
`

// Get min & max values for grouped miners (hosted + marketplace)
export const getHostedProductsRanges = gql`
  query AllHostedProductsRanges($minerModelNames: [String!]) {
    hostedProductsRanges: allAvailableMinerModels(
      filter: { minerModelName: { in: $minerModelNames } }
    ) {
      nodes {
        minerModelName
        hostedMinerListingsTotal
        marketplaceMinerListingsTotal
        productListingPriceMinMarketplace
        productListingPriceMinHosted
        productPricePerTerahashMin
        minerModelHashratePerTerahashMax
        minerModelHashratePerTerahashMin
        monthlyHostingChargesMin
      }
    }
  }
`

export const getMarketplaceProductsByMinerModelName = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  ${COMMON_PRODUCT_OPTIONAL_FIELDS}
  query allProductsByMinerModelName($minerModelName: String!) {
    availableProducts: allAvailableProducts(
      condition: { minerModelName: $minerModelName, productDisplayCategory: MARKETPLACE }
      first: ${DEFAULT_LIMIT}
      orderBy: PRODUCT_LISTING_PRICE_ASC
    ) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        ...CommonProductOptionalFields
        productDateListed
      }
    }
    # check if miner with this name exists
    unavailableProductsNames: allAvailableProducts(
      condition: { minerModelName: $minerModelName }
      first: 1
    ) {
      nodes {
        minerModelName
      }
    }
  }
`

export const getSingleProductById = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  ${COMMON_PRODUCT_OPTIONAL_FIELDS}
  query getSingleProductByProductId(
    $productId: UUID!
    $minerModelName: String!
  ) {
    availableProducts: allAvailableProducts(
      condition: { productId: $productId }
    ) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        ...CommonProductOptionalFields
        productDateListed
      }
    }
    # check if miner with this name exists
    unavailableProductsNames: allAvailableProducts(
      condition: { minerModelName: $minerModelName }
      first: 1
    ) {
      nodes {
        minerModelName
      }
    }
  }
`

export const getAllMinerProductsByFacilityId = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  query allMinerProductsByFacilityId($facilityId: UUID!) {
    allAvailableProducts(filter: { facilityId: { equalTo: $facilityId } }) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        facilityId
        facilityDisplayName
        hostedPrepaymentMonths
        hostedDepositMonths
        hostedContractLengthMonths
        hostedTargetOnlineDate
        nonhostedEarliestShippingDate
        nonhostedShippingTimeDescription
        marketplacePrepaymentMonths
        marketplaceDepositMonths
      }
    }
  }
`
export const getAllMinerProductsByMinerModelName = gql`
  ${CORE_SINGLE_PRODUCT_FIELDS}
  ${CORE_SINGLE_MINER_FIELDS}
  query getAllMinerProductsByMinerModelName($minerModelName: String!) {
    allAvailableProducts(
      filter: { minerModelName: { equalTo: $minerModelName } }
    ) {
      nodes {
        ...CoreSingleMinerFields
        ...CoreSingleProductFields
        facilityId
        facilityDisplayName
        hostedPrepaymentMonths
        hostedDepositMonths
        hostedContractLengthMonths
        hostedTargetOnlineDate
        nonhostedEarliestShippingDate
        nonhostedShippingTimeDescription
        marketplacePrepaymentMonths
        marketplaceDepositMonths
      }
    }
  }
`

export const getProductImageByName = gql`
  query ProductImageByMinerName($minerModelName: String!) {
    allAvailableProducts(
      first: 1
      filter: { minerModelName: { equalTo: $minerModelName } }
    ) {
      nodes {
        productImagesPrioritized
      }
    }
  }
`

export const getMyMinersListView = gql`
  query MinersList(
    $offset: Int
    $pageLimit: Int = 10
    $filter: UserDashboardHardwareMinerFilter
  ) {
    userDashboardHardwareMiners(
      first: $pageLimit
      offset: $offset
      filter: $filter
    ) {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      nodes {
        isAhm
        hardwareModelName
        hardwareModelAlgorithm
        minersInApproval
        minersInShipped
        minersInMarketplace
        minersInRepair
        minersTotalFees
        minersTotalHashrate
        minersTotalPower
        minersCount
        minersCountMissingPool
        hardwareImages
        minersWithCoverage
        minersInPending
        facilities {
          code
          displayName
          id
          name
        }
      }
    }
  }
`

export const getMinersFromHardware = gql`
  query MinersFromHardware(
    $hardwareModelName: String
    $isAhm: Boolean
    $offset: Int
    $pageLimit: Int = 10
  ) {
    userDashboardMinersFromHardware(
      hardwareModelName: $hardwareModelName
      isAhm: $isAhm
      offset: $offset
      first: $pageLimit
    ) {
      nodes {
        billingPeriodsTotal
        carrier
        coverage {
          cost
          coveredAmount
          endDate
          invoiceId
          protectionItemId
          startDate
          status
        }
        facilityCode
        facilityDisplayName
        facilityId
        facilityName
        id
        marketplaceEligible
        marketplaceListingPrice
        marketplaceStatus
        minerCondition
        minerInMarketplace
        minerInTransit
        minerPurchase
        minerPurchaseDate
        miningPoolsAccountsNickName
        miningPoolsAccountsUsername
        miningPoolsName
        minerRepairStatus
        minerShortId
        minerStatus
        modelImage
        modelName
        monthlyFee
        orderStatus
        poolChangeStatus
        reservationId
        serialNumber
        start
        targetOnlineDate
        termEnd
        trackingNumber
        typeProduct
        workerName
        warrantyDate
        yearsContract
        isAhm
        hardwareAlgorithm
        hardwareHashrate
        hardwareModelName
        hardwarePower
        userArchived
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const getUserMiners = gql`
  query UserMiners(
    $offset: Int
    $pageLimit: Int = 10
    $filter: ResumeUserDashboardMinerFilter
    $orderBy: [ResumeUserDashboardMinersOrderBy!]
  ) {
    allResumeUserDashboardMiners(
      offset: $offset
      first: $pageLimit
      filter: $filter
      orderBy: $orderBy
    ) {
      nodes {
        id
        billingPeriodsTotal
        carrier
        facilityCode
        facilityDisplayName
        facilityId
        facilityName
        marketplaceEligible
        marketplaceListingPrice
        marketplaceStatus
        miningPoolsAccountsNickName
        miningPoolsAccountsUsername
        miningPoolsName
        minerCondition
        minerInMarketplace
        minerInTransit
        minerPurchase
        minerPurchaseDate
        minerRepairStatus
        minerShortId
        minerStatus
        modelImage
        modelName
        monthlyFee
        orderStatus
        poolChangeStatus
        reservationId
        targetOnlineDate
        coverage {
          cost
          coveredAmount
          endDate
          invoiceId
          protectionItemId
          startDate
          status
        }
        serialNumber
        start
        termEnd
        trackingNumber
        typeProduct
        workerName
        yearsContract
        isAhm
        hardwareAlgorithm
        hardwareHashrate
        hardwareModelName
        hardwarePower
        warrantyDate
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      totalCount
    }
  }
`

export const getMarketplaceListings = gql`
  query MinersList($filter: UserMarketplaceListingsRecordFilter) {
    userMarketplaceListings(filter: $filter) {
      nodes {
        facilityDisplayName
        minerId
        minerModelName
        minerListingPrice
        minerMarketplaceStatus
      }
    }
  }
`

export const getAllProductFilters = gql`
  query AllProductFilters {
    allProductFilters {
      nodes {
        productMinimumPrice
        productMaximumPrice
        productMinimumPricePerHashrate
        productMaximumPricePerHashrate
        productMinimumPower
        productMaximumPower
        productMinimumHashrate
        productMaximumHashrate
        productManufacturerName
        productAlgorithm
        productCountry
        productModelName
        productDisplayCategory
      }
    }
  }
`

export const getAvailableCompassCredits = gql`
  query AvailableCompassCredits {
    availableCompassCredits
  }
`

export const getUserStatistics = gql`
  query UserStatistics {
    userDashboardTotals {
      edges {
        node {
          totalAhmHashrate {
            algorithm
            hashrate
          }
          totalAhmMiners
          totalHostedEfficiency {
            algorithm
            efficiency
          }
          totalHostedHashrate {
            algorithm
            hashrate
          }
          totalHostedMiners
          totalMiners
          totalMonthlyFees
          totalOnlineMiners
        }
      }
    }
  }
`

export const getUserCompassWallet = gql`
  query getUserCompassWallet {
    compassWallet {
      edges {
        node {
          totalUsd
          availableUsd
          availableBtc
          availableBtcToUsdEquivalence
          availableUsdc
          availableUsdcToUsdEquivalence
          availableUsdt
          availableUsdtToUsdEquivalence
        }
      }
    }
  }
`

export const delistSelectedMiners = gql`
  mutation delistSelectedMiners($listedMinerId: UUID!) {
    delistMiner(input: { listedMinerId: $listedMinerId }) {
      clientMutationId
      boolean
    }
  }
`
/* only to set prices for the first time */
export const setMinersPrices = gql`
  mutation SetMinersPrices($listedMinerId: UUID!, $minerPrice: Float!) {
    listMinerForSale(
      input: { listedMinerId: $listedMinerId, minerPrice: $minerPrice }
    ) {
      clientMutationId
      boolean
    }
  }
`
/* only to update/edit prices when miner's price is already set */
export const updateMinersPrices = gql`
  mutation UpdateMinersPrices($listedMinerId: UUID!, $minerPrice: Float!) {
    updateMinerPrice(
      input: { listedMinerId: $listedMinerId, minerPrice: $minerPrice }
    ) {
      clientMutationId
      boolean
    }
  }
`
export const getMinerPricesFees = gql`
  query GetMinerPricesFees($minersToSell: [MinerPriceInput]!) {
    saleListingSummary(minersToSell: $minersToSell) {
      nodes {
        totals {
          listingTotal
          saleFees
          saleRevenue
        }
        hardwares {
          hardwareHashrate
          hardwareModelAlgorithm
          hardwareModelName
          hardwarePower
          miners {
            feeApplied
            minerId
            minerPrice
            payoutPrice
            saleFee
          }
        }
      }
    }
  }
`

export const getIsProtectionBannerDisplay = gql`
  query GetIsProtectionBannerDisplay {
    pdpDisplayBanner
  }
`

export const getHasMinersForSale = gql`
  query GetHasMinersForSale {
    userHasEligibleMinersForSale
  }
`

export const getPdpAvailableMinersByFacility = gql`
  query GetPdpAvailableMinersByFacility($minerIds: [UUID]) {
    pdpAvailableMinersByFacility(minerIds: $minerIds) {
      nodes {
        miners {
          coveredAmount
          endDate
          facilityId
          hardwareHashrate
          hardwareModelName
          hardwarePower
          invoiceId
          minerId
          orderId
          price
          startDate
          shortId
          status
        }
        facilityDisplayName
        facilityId
        facilityCountryCode
        facilityName
      }
    }
  }
`

export const createProtectionInvoice = gql`
  mutation CreateProtectionInvoice($minerArray: [UUID]!) {
    createProtectionInvoices(input: { minerArray: $minerArray }) {
      boolean
      clientMutationId
    }
  }
`
